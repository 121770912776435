'use client';
import DefaultStyle from 'lowcode/common/DefaultStyle';
import EditorImg from 'lowcode/components/EditorImg';
import EditorLink from 'lowcode/components/EditorLink';
import sanitizeHtml from 'sanitize-html';
import styles from './index.module.scss';

export default function Content({ id, data, attr = {} }: any) {
    const { title, moreLink, moreText, list } = data;
    return (
        <div
            className={`${styles.content} temp-hide temp-${id} GtmModuleData-section`}
            id={id}
            data-section-id="editor-ListNewsCardB"
            data-section-id2={id}
            data-section-classname=".ListNewsCard-title"
        >
            <DefaultStyle id={id} attr={attr} />
            <div className="ListNewsCard-title">{title}</div>
            <div className="ListNewsCard-list">
                {list?.map((item: any, index: number) => {
                    return (
                        <div key={index} className="ListNewsCard-item">
                            <EditorLink
                                href={item.link}
                                className="ListNewsCard-item-in"
                                dataLayer={{
                                    event_name: 'learn_more',
                                    page_name: item.title,
                                    list_name: title,
                                    button_name: 'none',
                                }}
                            >
                                <EditorImg className="ListNewsCard-img" pc={item.pc} h5={item.h5} />
                                <div className="ListNewsCard-msgs">
                                    <div
                                        className="ListNewsCard-item-title"
                                        style={{
                                            color: item.theme,
                                        }}
                                    >
                                        {item.title}
                                    </div>
                                    <div
                                        className="ListNewsCard-item-desc"
                                        dangerouslySetInnerHTML={{
                                            __html: sanitizeHtml(item?.desc, {
                                                allowedTags: [''],
                                            }),
                                        }}
                                    ></div>
                                    <div className="ListNewsCard-item-footer">
                                        <div className="ListNewsCard-item-logo">
                                            <EditorImg
                                                className="ListNewsCard-item-logo-svg"
                                                pc={'https://websiteoss.ecoflow.com/upload/image/1744339350834/logo.svg'}
                                            />
                                        </div>
                                        <div className="ListNewsCard-item-date">ECOFLOW {item.date}</div>
                                    </div>
                                </div>
                            </EditorLink>
                        </div>
                    );
                })}
            </div>
            {moreLink && moreText && (
                <EditorLink href={moreLink} className="ListNewsCard-btn">
                    {moreText}
                    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
                        <path
                            d="M5.05197 2.2605L9.0603 6.26883L5.05197 10.2772"
                            stroke="#3F68E0"
                            strokeWidth="1.05219"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                </EditorLink>
            )}
        </div>
    );
}
